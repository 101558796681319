// Translated
// Migrated
<template>
  <img :src="iconUrl" />
</template>

<script>
export default defineNuxtComponent({
  name: 'CampaignIcon',
  props: {
    icon: {
      type: String,
      required: true,
    },

    color: {
      type: String,
      default: 'white',
    },

    size: {
      type: Number,
      default: 24,
    },
  },

  computed: {
    iconUrl () {
      return formatCloudinaryImagePath(this.icon, {
        transformation: { custom: `h_${this.size},e_colorize,co_${this.color}` },
        isSol: this.$isSol,
      })
    },
  },
})
</script>
